import {
  applyMiddleware,
  combineReducers,
  compose,
  legacy_createStore as createStore,
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'
import { globalReducer } from './global/reducers'

const injectedReducer = {}

const createSagaInjector = runSaga => {
  const injectedSagas = {}

  const isInjected = keyMFE => injectedSagas[keyMFE]

  const populateSaga = (sagasMFE, keys, sagasToInject) => {
    for (const key of keys) {
      for (const saga of sagasMFE[key]) {
        if (isInjected(saga.key)) {
          continue
        }

        sagasToInject.push(saga)

        injectedSagas[saga.key] = {
          ...saga,
          keyMFE: key,
          actionStorage: saga.action.toString(),
        }
      }
    }

    function* rootSagaMfe() {
      yield all(
        sagasToInject.map(function* ({ sagasFn, key, action }) {
          yield sagasFn(key, action)
        }),
      )
    }

    runSaga(rootSagaMfe)
  }

  const injectSaga = sagasMFE => {
    const keys = Object.keys(sagasMFE)
    const sagasToInject: any[] = []

    // sobrescrever função quando a chave existir
    populateSaga(sagasMFE, keys, sagasToInject)
  }

  return {
    injectSaga,
  }
}

function createReducerInjector(store, reducers, keyMFE) {
  injectedReducer[keyMFE] = reducers

  const reducersToInjectKeys = Object.keys(injectedReducer)

  let reducersToInjectCombined = {}

  for (const key of reducersToInjectKeys) {
    reducersToInjectCombined = {
      ...reducersToInjectCombined,
      ...injectedReducer[key],
    }
  }

  store.replaceReducer(
    combineReducers({
      ...reducersToInjectCombined,
      ...reducersToCombineGlobal,
    }),
  )

  return store
}

const reducersToCombineGlobal = {
  globalReducer,
}

export const sagaMiddleware = createSagaMiddleware()

export const reducers = combineReducers(reducersToCombineGlobal)

export const configureStore = () => {
  // Create a store with the root reducer function being the one exposed by the manager.
  const store = createStore(
    reducers,
    composeWithDevTools(compose(applyMiddleware(sagaMiddleware))),
  )

  const injectorSagas = createSagaInjector(sagaMiddleware.run)

  return {
    store,
    reducerInjector: ({ reducers, keyMFE }) =>
      createReducerInjector(store, reducers, keyMFE),
    sagasInjector: ({ sagas }) => {
      injectorSagas.injectSaga(sagas)
    },
  }
}
