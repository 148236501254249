import { appApi } from '../../apis'
import { localPartnerService } from '../partner/services'

const localPartner = localPartnerService()

export async function fetchUserGrantsService(payload?: {
  contractId: string
}) {
  try {
    const partnerStorage = localPartner.getCurrent()

    const accessToken = partnerStorage?.user?.accessToken
    if (!accessToken) {
      throw new Error('No access token')
    }

    const endpoint = `/escrow/rbac/grants`
    const response = await appApi.get(endpoint, {
      params: {
        contractId: payload?.contractId || undefined,
      },
    })
    const { grants, user } = response.data

    return {
      grants,
      contractId: payload?.contractId,
      role: user?.auth?.majorityProfile || user.role,
      profiles: user?.profiles,
      name: user?.auth?.name,
      contractRoles: user?.auth?.roles,
    }
  } catch (error) {
    const err: any = error
    err.contractId = payload?.contractId
    throw err
  }
}
