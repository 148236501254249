import { registerApplication, start } from 'single-spa'
import {
  constructApplications,
  constructLayoutEngine,
  constructRoutes,
} from 'single-spa-layout'
import { configureStore } from './ducks/configure-store'
import { actionTypes, selector } from './ducks/global'
import microfrontendLayout from './microfrontend-layout.html'

// @ts-ignore
import { StyleGuideComponents } from '@escrow/nacional-styleguide'

const { store, sagasInjector, reducerInjector } = configureStore()

const data: any = {
  props: {
    globalRedux: {
      store,
      selector,
      actionTypes,
      sagasInjector,
      reducerInjector,
    },
  }, // customProps
  loaders: {
    default: StyleGuideComponents.LoadingLazyPage(),
  },
}

const routes = constructRoutes(microfrontendLayout, data)

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name)
  },
})
const layoutEngine = constructLayoutEngine({
  routes,
  applications,
  active: false,
})

applications.forEach(registerApplication)

System.import('@escrow/nacional-styleguide').then(() => {
  // Activate the layout engine once the styleguide CSS is loaded
  layoutEngine.activate()
  start({
    urlRerouteOnly: false,
  })
})
